import React, { useEffect, useRef, useState } from "react";
import Header from "./layout/Header";
import Radio from "./pages/Radio";
import "./App.css";

const stations = [
  "95.7 – I-95 Radio",
  "99.9 – WBFM",
  "101.3 – Club 101",
  "105.5 – Harlem River Radio",
  "107.1 – sublet.wav",
];

const playlists = [
  "https://soundcloud.com/broadcastradio/sets/i95radio",
  "https://soundcloud.com/broadcastradio/sets/wbfm",
  "https://soundcloud.com/broadcastradio/sets/club101",
  "https://soundcloud.com/broadcastradio/sets/harlemriverradio",
  "https://soundcloud.com/broadcastradio/sets/subletwav",
];

function App() {
  const [loopState, setLoopState] = useState(false);
  // const [showMobile, setShowMobile] = useState(false);
  const [currPlaylist, setCurrPlaylist] = useState(1);
  // const isShuffleChecked = useRef(false);

  const toggleShuffle = () => {
    setLoopState((prevState) => {
      let loop;
      if (prevState === "track") {
        loop = "song";
      } else if (prevState === "song") {
        loop = false;
      } else {
        loop = "track";
      }

      return loop;
    });
  };

  // useEffect(() => {
  //   if (window.innerWidth > 965) {
  //     setShowMobile(false);
  //   } else {
  //     setShowMobile(true);
  //   }
  //   window.addEventListener("resize", () => {
  //     if (window.innerWidth > 965) {
  //       setShowMobile(false);
  //     } else {
  //       setShowMobile(true);
  //     }
  //   });
  // }, []);

  return (
    <div className="App">
      <Header />
      {playlists.map((el, idx) => {
        if (currPlaylist !== idx + 1) return null;

        return (
          <React.Fragment key={"pc_" + idx}>
            {/* {showMobile ? (
              <>
                <RadioMob
                  loopState={loopState}
                  playlistTitle={stations[idx]}
                  toggleShuffle={toggleShuffle}
                  url={el}
                  itemNum={idx + 1}
                  setCurrPlaylist={setCurrPlaylist}
                />
              </>
            ) : ( */}
            <>
              <Radio
                loopState={loopState}
                playlistTitle={stations[idx]}
                toggleShuffle={toggleShuffle}
                url={el}
                itemNum={idx + 1}
                setCurrPlaylist={setCurrPlaylist}
              />
            </>
            {/* )} */}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default App;
