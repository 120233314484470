import { useState } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.vite";

const Header = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      {modal && <Modal closeModal={() => setModal(false)} />}
      <div className="flex h-[70px] bg-black justify-center  items-center w-full">
        <div className="flex justify-between items-center w-[90%] max-w-[1100px]">
          <img src="/logo.png" className="w-8 h-8 object-contain" alt="" />
          <div className="flex justify-center items-center gap-6 md:gap-12">
            <button
              data-modal-target="defaultModal"
              data-modal-toggle="defaultModal"
              // className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              onClick={() => setModal(true)}
              className="text-white tracking-[2px]  lowercase no-underline font-typeWriter text-[10px] sm:text-[12px] cursor-pointer"
            >
              [Owner's Manual]
            </button>
            {/* <a
            target={"blank"}
            download
            href="/manual.pdf"
            className="text-white tracking-[2px]  lowercase no-underline font-typeWriter text-[10px] sm:text-[12px] cursor-pointer"
          >
            [Owner's Manual]
          </a> */}
            <a
              href="https://www.instagram.com/broadcastradio.sound/"
              target={"blank"}
              className="text-white tracking-[2px]   lowercase no-underline font-typeWriter text-[10px] sm:text-[12px] cursor-pointer"
            >
              [Instagram]
            </a>{" "}
            <a
              href="https://www.wbnyc.tv/"
              target={"blank"}
              className="text-white tracking-[2px]   lowercase no-underline font-typeWriter text-[10px] sm:text-[12px] cursor-pointer"
            >
              [Shop]
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const Modal = ({ closeModal }) => {
  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden md:inset-0 h-modal h-full flex justify-center items-center"
    >
      <div className="relative w-full h-full max-w-6xl">
        <div className="relative bg-white rounded-lg shadow flex flex-col max-h-[90%]">
          <div className="flex items-start justify-between p-4 rounded-t">
            <h3 className="text-xl font-semibold text-gray-900"></h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="defaultModal"
              onClick={closeModal}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-6 space-y-6 overflow-y-auto">
            <img
              className="md:min-h-[1000px] sm:min-h-[648px] w-full sm:w-auto object-contain mx-auto"
              src="./broadcastradio_ownersmanual_1.jpg"
              alt="manual"
            />{" "}
            <img
              className="md:min-h-[1000px] sm:min-h-[648px] w-full sm:w-auto object-contain mx-auto"
              src="./broadcastradio_ownersmanual_2.jpg"
              alt="manual"
            />
            {/* <img className="min-h-[1000px]" src="/manual.png" alt="manual" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
