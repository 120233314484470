import { useEffect, useRef, useState, createRef } from "react";
import CircularSlider from "react-circular-slider-svg";
import RangeSlider from "react-range-slider-input";
import loadscript from "load-script";
import "react-range-slider-input/dist/style.css";

import msToTime from "../utils/msToTime";

const Radio = ({
  url,
  itemNum,
  setCurrPlaylist,
  toggleShuffle,
  playlistTitle,
  loopState,
}) => {
  const loopStateRef = useRef(false);
  const playlistIdxRef = useRef();
  const playRef = useRef();
  const playMobRef = useRef();
  const [songProgress, setSongProgress] = useState(0);
  const [elaspedTime, setElaspedTime] = useState(0);
  const [currentAudio, setCurrentAudio] = useState({ name: "", duration: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [showMobile, setShowMobile] = useState(false);

  // used to communicate between SC widget and React
  const [isPlaying, setIsPlaying] = useState(false);
  const [playlistIndex, setPlaylistIndex] = useState(0);

  // populated once SoundCloud Widget API is loaded and initialized
  const [player, setPlayer] = useState(false);

  const iframeRef = createRef();

  const handleSongProgress = (val) => {
    // if (!iframeRef.current) return;
    setSongProgress(val);
    const player = window.SC.Widget("sound-cloud-player");
    // const player = window.SC.Widget(iframeRef.current);
    player?.getCurrentSound((playerSound) => {
      player?.seekTo(val * playerSound?.duration);
    });
  };

  useEffect(() => {
    if (window.innerWidth > 965) {
      setShowMobile(false);
    } else {
      setShowMobile(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth > 965) {
        setShowMobile(false);
      } else {
        setShowMobile(true);
      }
    });
  }, []);

  // initialization - load soundcloud widget API and set SC event listeners

  useEffect(() => {
    // if (!iframeRef.current) return;
    // use load-script module to load SC Widget API
    loadscript("https://w.soundcloud.com/player/api.js", () => {
      // initialize player and store reference in state
      // if (!iframeRef.current) return;
      const player = window.SC.Widget("sound-cloud-player");
      // const player = window.SC.Widget(iframeRef.current);

      if (!player) return;
      setPlayer(player);

      const { PLAY, PLAY_PROGRESS, PAUSE, FINISH, ERROR, READY } =
        window.SC.Widget.Events;

      // NOTE: closures created - cannot access react state or props from within and SC callback functions!!

      player?.bind(READY, () => {
        if (playRef.current) playRef.current.click();
        if (playMobRef.current) playMobRef.current.click();
        setIsLoading(false);
      });

      player?.bind(PLAY, () => {
        if (!player) return;
        // update state to playing
        setIsPlaying(true);
        // player?.setVolume(0.01);
        player?.getCurrentSoundIndex((playerPlaylistIndex) => {
          playlistIdxRef.current = playerPlaylistIndex;
          setPlaylistIndex(playerPlaylistIndex);
        });

        player?.getCurrentSound((playerPlaylistSound) => {
          setCurrentAudio({
            name: playerPlaylistSound.title,
            duration: playerPlaylistSound.duration,
          });
        });
      });

      player?.bind(PAUSE, () => {
        // update state if player has paused - must double check isPaused since false positives
        if (!player) return;
        player?.isPaused((playerIsPaused) => {
          if (playerIsPaused) setIsPlaying(false);
        });
      });

      player?.bind(PLAY_PROGRESS, () => {
        if (!player) return;
        player?.getPosition((playerPosition) => {
          setElaspedTime(playerPosition);
          player?.getCurrentSound((playerSound) => {
            const progress = playerPosition / playerSound.duration;
            if (
              loopStateRef.current === "song" &&
              playerSound.duration - playerPosition <= 500
            )
              player?.seekTo(0);
            setSongProgress(progress);
          });
        });
      });

      player?.bind(FINISH, (val) => {
        if (!player) return;
        player?.getSounds((playerSounds) => {
          if (loopStateRef.current === "song") {
            player?.skip(playlistIdxRef.current);
            return;
          }
          if (playlistIdxRef.current + 1 === playerSounds.length) {
            let newItemNum = itemNum + 1;
            if (newItemNum > 5) newItemNum = 1;
            if (loopStateRef.current !== "track") {
              if (loopStateRef.current !== "song") {
                setCurrPlaylist(newItemNum);
              } else {
                // setTimeout(() => {
                //   if (playRef.current) playRef.current.click();
                // }, 200);
              }
            } else if (loopStateRef.current === "track") {
              playlistIdxRef.current = 0;
              setPlaylistIndex(0);
              player?.skip(0);
            }
          }
        });
      });
    });
  }, [loopState]);

  useEffect(() => {
    loopStateRef.current = loopState;
  }, [loopState]);

  // integration - update SC player based on new state (e.g. play button in React section was click)

  // adjust playback in SC player to match isPlaying state
  useEffect(() => {
    if (!player) return; // player loaded async - make sure available

    player?.isPaused((playerIsPaused) => {
      if (isPlaying && playerIsPaused) {
        player?.play();
      } else if (!isPlaying && !playerIsPaused) {
        player?.pause();
      }
    });
  }, [isPlaying]);

  // adjust seleted song in SC player playlist if playlistIndex state has changed
  useEffect(() => {
    if (!player) return; // player loaded async - make sure available

    player?.getCurrentSoundIndex((playerPlaylistIndex) => {
      if (playerPlaylistIndex !== playlistIndex) player?.skip(playlistIndex);
    });
  }, [playlistIndex]);

  // React section button click event handlers (play/next/previous)
  //  - adjust React component state based on click events

  const togglePlayback = () => {
    setIsPlaying(!isPlaying);
  };

  const changePlaylistIndex = (skipForward = true) => {
    if (!player) return;
    // get list of songs from SC widget
    player?.getSounds((playerSongList) => {
      let nextIndex = skipForward ? playlistIndex + 1 : playlistIndex - 1;

      // ensure index is not set to less than 0 or greater than playlist
      if (nextIndex < 0) {
        let newItemNum = itemNum - 1;
        if (newItemNum < 1) newItemNum = 5;
        if (loopState !== "track") setCurrPlaylist(newItemNum);
        else {
          playlistIdxRef.current = playerSongList.length - 1;
          setPlaylistIndex(playerSongList.length - 1);
          player?.skip(playerSongList.length - 1);
        }
        return;
      } else if (nextIndex >= playerSongList.length) {
        let newItemNum = itemNum + 1;
        if (newItemNum > 5) newItemNum = 1;
        if (loopState !== "track") {
          setCurrPlaylist(newItemNum);
        } else {
          playlistIdxRef.current = 0;
          setPlaylistIndex(0);
          player?.skip(0);
          // playRef.current.click();
        }
        return;
      }

      setPlaylistIndex(nextIndex);
      playlistIdxRef.current = nextIndex;
    });
  };

  useEffect(() => {
    document.title = currentAudio.name;
  }, [currentAudio, iframeRef]);

  if (!url) return null;

  return (
    <>
      <iframe
        className="hidden"
        ref={iframeRef}
        id="sound-cloud-player"
        title="sound-cloud-player"
        style={{ border: "none", height: 314, width: 400 }}
        scrolling="no"
        allow="autoplay"
        src={`https://w.soundcloud.com/player/?url=${url}`}
      ></iframe>

      {!showMobile ? (
        <div className="flex flex-col w-[940px] relative justify-center  mx-auto items-center ">
          <div
            className={`flex w-[940px] relative justify-center mt-[180px]   mx-auto items-center ${
              isLoading ? "opacity-50 pointer-events-none" : ""
            }`}
          >
            <img
              src="/bodyDesk.png"
              className="w-[940px] select-none object-contain max-w-[940px]"
              alt=""
            />
            <p className="absolute leading-[1] right-[3%] top-[69.2%] font-bold flex justify-center items-center gap-1 font-eurostile text-[7px] uppercase">
              <span className="border-[1.2px] border-solid border-black min-w-[5px] min-h-[5px] rounded-full"></span>{" "}
              Prev/Next
            </p>
            <p className="absolute left-[3%] top-[69.2%] leading-[1] font-bold flex justify-center items-center gap-1 font-eurostile text-[7px] uppercase">
              <span className="border-[1.2px] border-solid border-black min-w-[5px] min-h-[5px] rounded-full"></span>{" "}
              FWD/REV
            </p>
            <div className="absolute  select-none left-[40px] top-[32px]">
              <img src="/volume.png" className="w-[160px] select-none" alt="" />
              <div className="left-1/2 handle z-10 absolute -translate-x-1/2 select-none top-1/2 -translate-y-1/2">
                <CircularSlider
                  size={200}
                  minValue={0}
                  maxValue={1}
                  startAngle={0}
                  endAngle={360}
                  angleType={{
                    direction: "cw",
                    axis: "+y",
                  }}
                  handle1={{
                    value: songProgress,
                    onChange: (v) => handleSongProgress(v),
                  }}
                  arcColor="#00a550"
                  arcBackgroundColor="#000"
                />
              </div>
            </div>
            {/* //center screen -------------- */}
            <div className="absolute flex justify-between items-start px-[10px] flex-col h-[108px] w-[40.4%] left-[50.4%] top-[73.5px] -translate-x-1/2">
              <img
                src="/center.png"
                className="absolute select-none left-0 top-1/2 -translate-y-1/2 w-full -z-10 object-contain"
                alt=""
              />
              <div className="flex justify-start items-start flex-col gap-2">
                <p className="font-alarm     text-green text-[16px] text-left">
                  {playlistTitle || "95.7 - i.95 RaDIO"}
                </p>
                <p className="font-alarm leading-[1]     text-green text-[16px] text-left">
                  {currentAudio.name}
                </p>
              </div>
              <div className="flex justify-between items-center gap-1 w-full">
                <p className="font-alarm text-[16px] leading-4 text-green  text-left">
                  {elaspedTime ? msToTime(elaspedTime) : "0:00"}
                </p>
                <p className="font-alarm text-[16px] leading-4 text-green  text-left">
                  {currentAudio?.duration
                    ? msToTime(currentAudio?.duration)
                    : "00:00"}
                </p>
              </div>
            </div>
            {/* //center screen -------------- */}
            <div className="flex  rounded-full justify-center items-center  absolute isolate top-[32px] right-[40px]">
              <div className="absolute -inset-[3px] bg-[#000] rounded-full -z-10"></div>
              <img
                onClick={() => changePlaylistIndex(false)}
                src="/prev.png"
                className="w-[80px] cursor-pointer relative -right-[1px]"
                alt=""
              />
              <img
                onClick={() => changePlaylistIndex(true)}
                src="/next.png"
                className="w-[80px] cursor-pointer relative -left-[1px]"
                alt=""
              />
            </div>
            <div className="flex justify-between items-center w-[80%] absolute bottom-4 left-1/2 -translate-x-1/2">
              <div
                onClick={togglePlayback}
                className="relative cursor-pointer"
                ref={playRef}
              >
                <img
                  src="/btn.png"
                  className="h-[35px] object-contain"
                  alt=""
                />
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center gap-1">
                  <img
                    src={isPlaying ? "/pause.png" : "/play.png"}
                    className="w-2 object-contain"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex justify-center items-center gap-2">
                <div
                  onClick={() => setCurrPlaylist(1)}
                  className="relative cursor-pointer"
                >
                  <img
                    src="/long.png"
                    className=" h-[35px] object-contain"
                    alt=""
                  />
                  <p
                    className={`text-[11px] font-bold font-helvetica ${
                      itemNum === 1 ? "text-green" : "text-black"
                    } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                  >
                    1
                  </p>
                </div>
                <div
                  onClick={() => setCurrPlaylist(2)}
                  className="relative cursor-pointer"
                >
                  <img
                    src="/long.png"
                    className=" h-[35px] object-contain"
                    alt=""
                  />
                  <p
                    className={`text-[11px] font-bold font-helvetica ${
                      itemNum === 2 ? "text-green" : "text-black"
                    } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                  >
                    2
                  </p>
                </div>
                <div
                  onClick={() => setCurrPlaylist(3)}
                  className="relative cursor-pointer"
                >
                  <img
                    src="/long.png"
                    className=" h-[35px] object-contain"
                    alt=""
                  />
                  <p
                    className={`text-[11px] font-bold font-helvetica ${
                      itemNum === 3 ? "text-green" : "text-black"
                    } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                  >
                    3
                  </p>
                </div>
                <div
                  onClick={() => setCurrPlaylist(4)}
                  className="relative cursor-pointer"
                >
                  <img
                    src="/long.png"
                    className=" h-[35px] object-contain"
                    alt=""
                  />
                  <p
                    className={`text-[11px] font-bold font-helvetica ${
                      itemNum === 4 ? "text-green" : "text-black"
                    } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                  >
                    4
                  </p>
                </div>
                <div
                  onClick={() => setCurrPlaylist(5)}
                  className="relative cursor-pointer"
                >
                  <img
                    src="/long.png"
                    className=" h-[35px] object-contain"
                    alt=""
                  />
                  <p
                    className={`text-[11px] font-bold font-helvetica ${
                      itemNum === 5 ? "text-green" : "text-black"
                    } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                  >
                    5
                  </p>
                </div>
              </div>
              <div className="relative cursor-pointer" onClick={toggleShuffle}>
                {loopState === "song" && (
                  <div className="rounded-full w-4 h-4 text-[10px] font-bold bg-black absolute top-0 right-0 text-white flex justify-center items-center">
                    1
                  </div>
                )}
                <img
                  src="/repeat.png"
                  className={`absolute left-1/2 top-1/2 -translate-x-1/2 w-4 object-contain -translate-y-1/2 ${
                    loopState ? "invert-green" : ""
                  }`}
                  alt=""
                />
                <img
                  src="/btn.png"
                  className="h-[35px] object-contain"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* <div className="w-full flex justify-end mt-5">
            <img
              width={250}
              src="/sticker.png"
              alt="sticker"
              className="mr-20"
            />
          </div> */}
        </div>
      ) : (
        <>
          <div
            className={`flex flex-col justify-center pt-[50px] w-full items-center absolute top-[45%] left-1/2 -translate-x-1/2 -translate-y-1/2 ${
              isLoading ? "opacity-50 pointer-events-none" : ""
            }`}
          >
            <div className="relative w-[100%] max-w-[345px]">
              <img
                src="/body-mob.png"
                className=" select-none object-contain w-full"
                alt=""
              />{" "}
              {/* //center screen -------------- */}
              <div className="absolute flex justify-between items-start flex-col px-[8px] pt-[2px] min-h-[100px] max-h-[100px] w-[79.5%] left-[50%] top-[13%] -translate-x-1/2">
                <img
                  src="/centerMob.png"
                  className="absolute -z-10 -translate-y-1/2 left-0 top-1/2 object-contain w-full"
                  alt=""
                />
                <div className="flex justify-start items-start flex-col gap-2">
                  <p className="font-alarm text-green text-[13px] text-left">
                    {playlistTitle || "95.7 - i.95 RaDIO"}
                  </p>
                  <p className="font-alarm leading-[1] text-green text-[13px]  text-left">
                    {currentAudio.name}
                  </p>
                </div>
                <div className="flex justify-between items-center gap-1 w-full">
                  <p className="font-alarm text-green text-[13px] text-left">
                    {elaspedTime ? msToTime(elaspedTime) : "0:00"}
                  </p>
                  <p className="font-alarm text-green text-[13px] text-left">
                    {currentAudio?.duration
                      ? msToTime(currentAudio?.duration)
                      : "00:00"}
                  </p>
                </div>
              </div>
              {/* //center screen ends-------------- */}
              <div className="flex justify-between min-h-[130px] max-h-[140px] flex-col items-center gap-0 sm:gap-2 w-full -translate-x-1/2 absolute bottom-3 sm:bottom-[20px] left-1/2 px-5">
                <div className="flex justify-center items-center gap-2">
                  <div
                    onClick={() => setCurrPlaylist(1)}
                    className="relative cursor-pointer"
                  >
                    <img
                      src="/btn.png"
                      className="w-[55px] object-contain"
                      alt=""
                    />
                    <p
                      className={`text-[8px] font-bold font-helvetica ${
                        itemNum === 1 ? "text-green" : "text-black"
                      } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                    >
                      1
                    </p>
                  </div>
                  <div
                    onClick={() => setCurrPlaylist(2)}
                    className="relative cursor-pointer"
                  >
                    <img
                      src="/btn.png"
                      className="w-[55px] object-contain"
                      alt=""
                    />
                    <p
                      className={`text-[8px] font-bold font-helvetica ${
                        itemNum === 2 ? "text-green" : "text-black"
                      } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                    >
                      2
                    </p>
                  </div>
                  <div
                    onClick={() => setCurrPlaylist(3)}
                    className="relative cursor-pointer"
                  >
                    <img
                      src="/btn.png"
                      className="w-[55px] object-contain"
                      alt=""
                    />
                    <p
                      className={`text-[8px] font-bold font-helvetica ${
                        itemNum === 3 ? "text-green" : "text-black"
                      } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                    >
                      3
                    </p>
                  </div>
                  <div
                    onClick={() => setCurrPlaylist(4)}
                    className="relative cursor-pointer"
                  >
                    <img
                      src="/btn.png"
                      className="w-[55px] object-contain"
                      alt=""
                    />
                    <p
                      className={`text-[8px] font-bold font-helvetica ${
                        itemNum === 4 ? "text-green" : "text-black"
                      } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                    >
                      4
                    </p>
                  </div>
                  <div
                    onClick={() => setCurrPlaylist(5)}
                    className="relative cursor-pointer"
                  >
                    <img
                      src="/btn.png"
                      className="w-[55px] object-contain"
                      alt=""
                    />
                    <p
                      className={`text-[8px] font-bold font-helvetica ${
                        itemNum === 5 ? "text-green" : "text-black"
                      } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
                    >
                      5
                    </p>
                  </div>
                </div>
                <RangeSlider
                  min={0}
                  max={1}
                  step={0.0001}
                  className="single-thumb"
                  defaultValue={[0, songProgress]}
                  value={[0, songProgress]}
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                  onInput={(val) => handleSongProgress(val[1])}
                />
                <div className="flex justify-center items-end w-full gap-4">
                  <div className="flex justify-center items-center flex-col gap-1">
                    <div
                      onClick={() => changePlaylistIndex(false)}
                      className="relative cursor-pointer"
                    >
                      <img
                        src="/btn.png"
                        className="w-[55px]  object-contain"
                        alt=""
                      />

                      <img
                        src="/prev-ico.png"
                        className="w-3 object-contain absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                        alt=""
                      />
                    </div>
                    {/* <p className="text-white font-helvetica font-normal text-center   text-[5.24px]">
                PREVIOUS
              </p> */}
                  </div>
                  <div className="flex justify-center items-center flex-col gap-1">
                    <div
                      ref={playMobRef}
                      onClick={togglePlayback}
                      className="relative cursor-pointer"
                    >
                      <img
                        src="/btn.png"
                        className="w-[55px]  object-contain"
                        alt=""
                      />
                      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center gap-1">
                        <img
                          src={isPlaying ? "/pause.png" : "/play.png"}
                          className="w-2 object-contain"
                          alt=""
                        />
                      </div>
                    </div>
                    {/* <p className="text-white font-helvetica font-normal text-center   text-[5.24px]">
                PLAY/PAUSE
              </p> */}
                  </div>
                  <div
                    className="flex justify-center items-center flex-col gap-1"
                    onClick={toggleShuffle}
                  >
                    <div className="relative cursor-pointer">
                      {loopState === "song" && (
                        <div className="rounded-full w-4 h-4 text-[10px] font-bold bg-black absolute top-0 right-0 text-white flex justify-center items-center">
                          1
                        </div>
                      )}
                      <img
                        src="/btn.png"
                        className="w-[55px] object-contain"
                        alt=""
                      />

                      <img
                        src="/repeat.png"
                        className={`w-4 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 object-contain ${
                          loopState ? "invert-green" : ""
                        }`}
                        alt=""
                      />
                    </div>
                    {/* <p className="text-white font-helvetica font-normal text-center   text-[5.24px]">
                SHUFFLE
              </p> */}
                  </div>
                  <div className="flex justify-center items-center flex-col gap-1">
                    <div
                      onClick={() => changePlaylistIndex(true)}
                      className="relative cursor-pointer"
                    >
                      <img
                        src="/btn.png"
                        className="w-[55px] object-contain"
                        alt=""
                      />

                      <img
                        src="/next-ico.png"
                        className="w-3 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 object-contain"
                        alt=""
                      />
                    </div>
                    {/* <p className="text-white font-helvetica font-normal text-center   text-[5.24px]">
                NEXT
              </p> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="w-full flex justify-center mt-5">
            <img
              width={150}
              src="/sticker.png"
              alt="sticker"
              // className="mr-20"
            />
          </div> */}
          </div>
     
        </>
      )}
    </>
  );
};

export default Radio;
